import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          
          <path d="M1712 5318 c3 -427 3 -434 26 -491 42 -103 152 -195 272 -227 24 -6
83 -12 130 -11 217 1 380 134 410 336 6 42 9 218 8 445 l-3 375 -110 0 -110 0
-5 -415 c-3 -281 -9 -423 -16 -440 -13 -28 -58 -72 -89 -87 -72 -36 -178 -20
-234 36 -58 58 -61 81 -61 520 l0 391 -110 0 -111 0 3 -432z"/>
<path d="M6965 5738 c-3 -7 -4 -267 -3 -578 l3 -565 100 0 100 0 2 30 c0 17 2
34 2 39 1 6 13 -2 29 -17 38 -36 105 -59 177 -59 221 -1 303 130 292 467 -6
160 -19 222 -62 287 -71 108 -269 138 -383 58 -23 -17 -45 -30 -47 -30 -3 0
-5 86 -5 190 l0 190 -100 0 c-71 0 -102 -4 -105 -12z m446 -516 c67 -75 67
-346 -1 -413 -35 -36 -92 -46 -145 -28 -31 11 -45 23 -62 57 -39 79 -40 277
-1 359 32 69 157 84 209 25z"/>
<path d="M2990 5437 c-151 -35 -233 -126 -233 -258 0 -113 52 -186 153 -218
20 -6 89 -17 153 -25 130 -15 157 -28 157 -78 0 -48 -17 -66 -77 -84 -85 -25
-195 -6 -261 43 l-34 26 -70 -69 -69 -69 32 -27 c75 -63 164 -89 315 -89 143
-1 223 23 297 90 85 77 97 232 27 329 -43 59 -113 89 -240 102 -130 13 -145
16 -164 36 -22 22 -20 77 4 99 42 38 180 36 259 -4 l38 -20 62 63 c68 68 68
66 0 108 -70 44 -252 67 -349 45z"/>
<path d="M3819 5440 c-102 -17 -197 -81 -230 -155 -25 -54 -25 -157 0 -211 23
-50 80 -98 135 -113 22 -6 92 -18 154 -25 62 -8 119 -20 128 -27 44 -37 36
-97 -18 -126 -50 -26 -178 -23 -243 6 -27 12 -57 29 -67 38 -18 16 -22 13 -83
-47 -36 -36 -65 -68 -65 -73 0 -21 92 -80 155 -99 54 -16 196 -26 269 -17 120
13 221 72 263 153 21 40 28 141 14 194 -14 51 -60 106 -110 131 -43 22 -118
38 -224 47 -90 7 -127 29 -127 74 0 40 30 71 76 79 58 10 157 -4 202 -27 l39
-20 63 63 63 64 -24 20 c-34 29 -120 60 -197 71 -76 11 -108 11 -173 0z"/>
<path d="M5420 5437 c-30 -7 -73 -22 -95 -32 -148 -72 -193 -273 -85 -384 48
-49 103 -67 255 -85 103 -12 121 -17 137 -37 45 -54 -4 -121 -98 -135 -61 -9
-165 13 -218 46 l-44 28 -67 -68 -67 -67 39 -32 c53 -43 132 -71 230 -80 222
-19 377 41 430 167 23 54 19 162 -6 216 -42 86 -110 118 -294 140 -124 14
-141 22 -152 67 -9 38 29 79 82 88 56 11 159 -5 202 -30 l36 -21 58 62 c32 34
56 67 54 73 -5 18 -97 65 -147 76 -81 18 -193 21 -250 8z"/>
<path d="M4310 5436 c0 -2 63 -175 140 -384 77 -210 140 -390 140 -401 0 -30
-40 -133 -59 -154 -10 -10 -39 -20 -69 -24 l-52 -6 0 -94 0 -95 77 7 c120 10
182 45 224 126 16 32 363 964 376 1012 5 15 -5 17 -104 17 l-108 0 -86 -262
-85 -263 -88 263 -88 262 -109 0 c-60 0 -109 -2 -109 -4z"/>
<path d="M6027 5433 c-11 -10 -8 -520 3 -596 12 -84 36 -132 90 -181 58 -53
96 -66 187 -66 83 0 161 27 193 65 14 17 15 16 20 -21 l5 -39 94 -3 c70 -3 96
0 102 10 10 14 11 800 2 824 -4 11 -28 14 -103 14 l-99 0 -3 -282 c-3 -310 -6
-325 -65 -364 -44 -29 -122 -25 -165 8 -52 40 -58 77 -58 377 l0 261 -98 0
c-54 0 -102 -3 -105 -7z"/>


</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
